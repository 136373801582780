<template>
    <div class="out-vr">
        <div class="big-content" @click="handleGoBack">
            <img src="../assets/image/new_return.png" alt="" class="go-back">
        </div>
        <iframe :src="vrUrl" frameborder="0" width="100%" height="100%">Sorry, your browser does not support
            iframes.</iframe>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                vrUrl: "",
                beforePage: false
            }
        },
        beforeRouteEnter(to, from, next) {
            if (from.name == null) {
                next(vm => {
                    vm.beforePage = true
                })
            } else {
                next()
            }
        },
        methods: {
            handleGoBack() {
                if (!this.beforePage) {
                    this.$router.go(-1)
                } else {
                    this.$router.push({
                        path: "/"
                    })
                }
            },
        },
        created() {
            this.vrUrl = this.$route.query.url
        },
    }
</script>
<style lang="scss" scoped>
    .out-vr {
        width: 100vw;
        height: 100vh;
        background: #292b2d;

        .big-content {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 200px;
            height: 200px;
            top: 0;
            left: 0;
            cursor: pointer;
            z-index: 2;
        }

        .go-back {
            width: 44px;
            height: 36px;
            /* top: 77px;
            left: 72px; */

        }
    }
</style>